import { render, staticRenderFns } from "./SubWorkflow.vue?vue&type=template&id=260383d8&scoped=true&"
import script from "./SubWorkflow.vue?vue&type=script&lang=js&"
export * from "./SubWorkflow.vue?vue&type=script&lang=js&"
import style0 from "./SubWorkflow.vue?vue&type=style&index=0&id=260383d8&prod&lang=scss&scoped=true&"
import style1 from "./SubWorkflow.vue?vue&type=style&index=1&id=260383d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260383d8",
  null
  
)

export default component.exports
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCircularProgress,QMenu,QChip,QAvatar,QChatMessage});
